import { IS_BROWSER } from '@alfa-bank/aff-common-ui/constants';

import { ApplicationState } from './shared/api/types';

if (IS_BROWSER) {
    // eslint-disable-next-line no-underscore-dangle
    window.__main = async (state: ApplicationState) => {
        const { initialize } = await import('./bootstrap');

        initialize(state);
    };
}
